"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    busyDelay: 500,
    appName: 'RWD Admin',
    genericError: 'Something went wrong, please try again',
    apiBase: 'https://adminapi.rwd.group',
    apiRoutes: {
        LOGIN: '/login',
        LOGOUT: '/logout',
        SELF: '/users/self',
        ALL_USERS: '/users',
        CLIENTS: '/clients',
        PROJECTS: '/projects',
        TASKS: '/tasks'
    },
    storageKeys: {
        USER: 'RWD_ADMIN_USER',
        PREFS: 'RWD_ADMIN_PREFS',
        SETTINGS: 'RWD_ADMIN_SETTINGS'
    },
    userRoles: {
        USER: 'ROLE_USER',
        ADMIN: 'ROLE_ADMIN',
        SUPER_ADMIN: 'ROLE_SUPER_ADMIN'
    }
};
