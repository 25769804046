"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskTypes = void 0;
var Store_1 = require("./Store");
var TaskTypes;
(function (TaskTypes) {
    TaskTypes["PLANNING"] = "Planning";
    TaskTypes["STRUCTURE"] = "Structure";
    TaskTypes["CONTENT"] = "Content";
    TaskTypes["DESIGN"] = "Design";
    TaskTypes["DEVELOPMENT"] = "Development";
    TaskTypes["DEVELOPMENT_OTHER"] = "Development (Other)";
    TaskTypes["ASSESSMENT"] = "Assessment";
    TaskTypes["TESTING"] = "Testing";
})(TaskTypes = exports.TaskTypes || (exports.TaskTypes = {}));
var ProjectStore = /** @class */ (function (_super) {
    __extends(ProjectStore, _super);
    function ProjectStore(data) {
        var _this = _super.call(this, data) || this;
        _this.setData = function (data) {
            _this.data = data;
        };
        return _this;
    }
    return ProjectStore;
}(Store_1.Store));
var projectStore = new ProjectStore([]);
exports.default = projectStore;
